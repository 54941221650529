import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Image from '@components/Image';
import {styled} from '@styles';
import {FluidObject} from 'gatsby-plugin-image';
import hq1Compressed from '../../images/hq_1_compressed.mp4';

export const Video = styled.video.attrs(() => ({
  autoPlay: true,
  playsInline: true,
  loop: true,
  muted: true,
  controls: false,
  ['aria-hidden']: true,
}))`
  min-width: 100%;
  object-fit: inherit;
  height: 100%;
  width: 100%;
`;

interface AmbientVideoProps {
  videoSrc?: string;
  className?: string;
  placeholderImage?: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}
export default function AmbientVideo({
  videoSrc,
  className,
  placeholderImage,
  ...props
}: AmbientVideoProps): JSX.Element | null {
  if (!videoSrc) videoSrc = hq1Compressed;
  return (
    <Video className={`AmbientVideo ${className}`} src={videoSrc} {...props}>
      <source src={videoSrc} />
      {placeholderImage && (
        <Image childImageSharp={placeholderImage.childImageSharp} alt="" {...placeholderImage} />
      )}
    </Video>
  );
}
