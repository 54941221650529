import React from 'react';

import {ResponsiveContainer} from '@components/layout';
import {styled} from '@styles';

/**
 * This component renders a 3x4 or 4x4 grid with
 * the bottom left 3 cells empty.
 */

interface Props {
  numberOfRows: number;
  gridItems: object[];
  GridItemComponent: Element;
}

const Grid = styled(ResponsiveContainer).withConfig({
  componentId: 'stairGrid'
})`
  display: grid;
  margin: 8.15em 0 0 0;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: ${({rows}) => `repeat(${rows}, 1fr)`};
  grid-column-gap: 2em;
  grid-row-gap: 3em;
  list-style: none;
  padding: 0;
`;

const StairGridItem = styled.li.withConfig({
  componentId: 'stairGridItem'
})`
  ${({numOfItems}) => {
    if (numOfItems >= 8) {
      return `
        :nth-child(9n) {
          grid-column: 2;
        }
        :nth-child(12n) {
          grid-column: 3;
        }

        :nth-child(14n) {
          grid-column: 4;
        }
      `;
    } else {
      return `
        :nth-child(5){
          grid-column: 2;
        }
      `;
    }
  }}
`;

const Items = ({
  numberOfRows,
  gridItems,
  GridItemComponent,
}: Props): JSX.Element[] => {
  return gridItems.map(item => (
    <StairGridItem numOfItems={gridItems.length} key={item.title}>
      <GridItemComponent {...item} />
    </StairGridItem>
  ));
};

const StairGrid = ({
  numberOfRows = 4,
  gridItems,
  GridItemComponent,
}: Props): JSX.Element | null => {
  return (
    <Grid rows={numberOfRows} as="ul">
      <Items
        numberOfRows={numberOfRows}
        gridItems={gridItems}
        GridItemComponent={GridItemComponent}
      />
    </Grid>
  );
};

export default StairGrid;
