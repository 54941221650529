import React, {SyntheticEvent} from 'react';
import {styled} from '@styles';
import {PlusButtonIcon} from '@components/icons';

type Props = React.PropsWithChildren<{
  onClick: (e: SyntheticEvent<unknown, unknown>) => void;
  open: boolean;
  expandLabel?: string
}>;

const Container = styled.div.attrs(({open, expandLabel='the list of guiding principles'}: {open: boolean, expandLabel?: string}) => ({
  ['aria-expanded']: open ? true : false,
  ['aria-label']: `Press enter to ${
    open ? 'collapse' : 'expand'
  } ${expandLabel}`,
}))``;

const PlusToggleButton = ({onClick, open, expandLabel}: Props): JSX.Element => (
  <Container onClick={onClick} open={Boolean(open)} expandLabel={expandLabel}>
    <PlusButtonIcon toggle={open} />
  </Container>
);

export default PlusToggleButton;
