import React from 'react';
import Card, {CardProps} from './Card';
import HeaderBlock, {HeaderBlockProps} from '@components/HeaderBlock';

import {styled, theme} from '@styles';
import {ResponsiveContainer} from '@components/layout';

interface CtaCardsGridProps {
  ctaheaderBlock: HeaderBlockProps;
  ctacardsBlock: {
    blockType: string;
    ctacard1Block: CardProps;
    ctacard2Block: CardProps;
    ctacard3Block: CardProps;
  };
}

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'ctaCardGridContainer'
})`
  margin: 0 auto;
  padding: ${theme.minPadding};

  &.wide {
    padding: 6em 2em 3em;
  }
`;

const ContentContainer = styled.div.withConfig({
  componentId: 'ctaCardGridContentContainer'
})`
  width: 100%;
  margin-bottom: 2em;
  display: flex;
  justify-content: flex-start;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .wide & {
    justify-content: flex-end;
    margin-bottom: 4em;
  }
`;

const HeaderContainer = styled.div.withConfig({
  componentId: 'ctaCardGridHeaderContainer'
})`
  position: initial;
  max-width: 558px;
  width: 100%;

  .wide & {
    position: relative;
  }
`;

const CtaCard = styled(Card).withConfig({
  componentId: 'ctaGardGridCTACard'
})`
  max-height: 100%;
  .wide & {
    max-height: 90%;
  }
`;

const CtaCardsGridContainer = styled.div.withConfig({
  componentId: 'ctaCardGridCtaCardsGridContainer'
})`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  height: auto;
  .wide & {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2em;
    height: 700px;
  }
`;

export default function CtaCardsGrid({
  ctaheaderBlock,
  ctacardsBlock,
}: CtaCardsGridProps): JSX.Element | null {
  if (!ctacardsBlock || Object.keys(ctacardsBlock).length === 0) return null;
  const {ctacard1Block, ctacard2Block, ctacard3Block} = ctacardsBlock;
  return (
    <Container>
      <ContentContainer>
        <HeaderContainer>
          <HeaderBlock {...ctaheaderBlock} />
        </HeaderContainer>
      </ContentContainer>
      <CtaCardsGridContainer>
        <CtaCard alignment="flex-start" {...ctacard1Block} />
        <CtaCard alignment="center" {...ctacard2Block} />
        <CtaCard alignment="flex-end" {...ctacard3Block} />
      </CtaCardsGridContainer>
    </Container>
  );
}
