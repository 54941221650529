import React from 'react';
import {graphql} from 'gatsby';
import {getImage, GatsbyImage, ImageDataLike} from 'gatsby-plugin-image';

export interface ImageProps {
  alt: string;
  loading?: 'lazy' | 'eager';
  childImageSharp: ImageDataLike;
}

export default function Image({
  alt,
  loading = 'lazy',
  ...props
}: ImageProps): JSX.Element | null {
  // let [startLoadTime, setStartLoadTime] = useState(0);

  let image = getImage(props.childImageSharp);
  // function reportImageLoadTime() {
  //   if (startLoadTime !== null && window && window.Sentry) {
  //     const now = Date.now();
  //     const duration = now - startLoadTime;
  //     const seconds = duration / 1000;
  //     const threshold = 1;

  //     if (seconds > threshold && loading !== 'lazy') {
  //       Sentry.captureMessage(`Image loading exceeded ${threshold} seconds`, {
  //         contexts: {
  //           state: {
  //             // value: {
  //             //   fixed: JSON.stringify(fixed || ''),
  //             //   fluid: JSON.stringify(fluid || ''),
  //             // },
  //           },
  //         },
  //       });
  //       console.log(`Image loaded in ${duration / 1000}s`);
  //     }
  //   }
  // }
  if (!image) {
    return null;
  }

  return (
    <GatsbyImage
      // onLoad={() => reportImageLoadTime()}
      // onStartLoad={() => setStartLoadTime(Date.now())}
      alt={alt}
      image={image}
      {...props}
    />
  );
}

export const fluidImageFragment = graphql`
  fragment fluidImageFragment on File {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH
        quality: $quality
      )
    }
  }
`;

export const cmsFluidImageFragment = graphql`
  fragment cmsFluidImageFragment on CMS_ImageNode {
    ...WagtailImageSharpSource
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, quality: 90)
    }
  }
`;

export const cmsFluidImageNoMaxWidthFragment = graphql`
  fragment cmsFluidImageNoMaxWidthFragment on CMS_ImageNode {
    ...WagtailImageSharpSource
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH, quality: 90)
    }
  }
`;

export const fixedImageFragment = graphql`
  fragment fixedImageFragment on File {
    childImageSharp {
      gatsbyImageData(
        layout: FIXED
        quality: $quality
        height: $height
        width: $width
      )
    }
  }
`;

export const heroCmsImageFragment = graphql`
  fragment heroCmsImageFragment on CMS_ImageBlock {
    image {
      ...WagtailImageSharpSource
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
    }
    attribution
    caption
    blockType
  }
`;

/* docz examples */

export {DoczSmallImageExample} from './DoczSmallImageExample';
export {DoczMediumImageExample} from './DoczMediumImageExample';
export {DoczLargeImageExample} from './DoczLargeImageExample';
export {default as ImageWithFocalPoint} from './ImageWithFocalPoint';