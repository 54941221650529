import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import Hero from '@components/Hero';
import AmbientVideo from '@components/Video/AmbientVideo';
import {ThemeProvider, styled, theme, Colors} from '@styles';
import {ResponsiveContainer, FontScale} from '@components/layout';
import TwoUOSParts from '@components/TwoUOSParts';
import {CtaCardsGrid} from '@components/Card';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import {
  LatestSection,
  StyledSection,
  CrosslinkSection,
} from '@components/sections';
import {
  HeroSectionCallout,
  BodySectionCallout,
} from '@components/SectionCallout';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  title: string;
  id: string;
  body: Block[];
  hero: Block[];
  icon: object;
}

const LatestStyledSection = styled(LatestSection).withConfig({
  componentId: 'twouosLatest'
})`
  background: ${Colors.TintedFogLight};
`;

const VideoContainer = styled(StyledSection).withConfig({
  componentId: 'twouosVidoContainer'
})`
  max-width: 100%;
  background: ${Colors.Transparent};

  .wide & {
    max-width: 80%;
  }
`;

const DarkContainer = styled(StyledSection).withConfig({
  componentId: 'twouosDarkContainer'
})`
  background: ${theme.bg};
  color: ${theme.fg};
`;

const TwoUOS = ({data}: {data: Data}): JSX.Element => {
  const {page} = data.cms;
  const {animatedVideo} = data;
  const {
    hero,
    body,
    seoTitle,
    searchDescription,
    canonicalUrl,
    parentPage,
  } = page;
  const {header, video} = getDataFromBlockTypes(hero);
  const {
    crossLink,
    principles,
    ctaCards,
    ctaCardsHeader,
  } = getDataFromBlockTypes(body);

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          minPadding: '2em',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              maxWidth: '600px',
              bg: Colors.Transparent,
              fg: Colors.White,
            }}
          >
            <Hero twouOS heroSectionType={'short'}>
              <HeroSectionCallout heroProps={[header]} />
            </Hero>
          </ThemeProvider>
          {video && <AmbientVideo videoSrc={video.video.url} />}
          {/* Intro Content */}
          <ThemeProvider
            theme={{
              fg: Colors.White,
              bg: Colors.Charcoal,
            }}
          >
            <DarkContainer>
              <ThemeProvider theme={{maxPadding: '5em 0', minPadding: '2em 0'}}>
                <StyledSection>
                  <BodySectionCallout
                    headerBlock={{
                      header: 'What is 2UOS.',
                      subheader:
                        'Part people. Part operating system. 2UOS is the backbone of all we do.',
                    }}
                    pagelinkBlock={null}
                    alignment={`center`}
                  />
                </StyledSection>
              </ThemeProvider>

              {/* Animated video */}
              {animatedVideo && (
                <ThemeProvider theme={{minPadding: '2em 0'}}>
                  <VideoContainer>
                    <AmbientVideo videoSrc={animatedVideo.publicURL} />
                  </VideoContainer>
                </ThemeProvider>
              )}

              {/* Card Grid */}
              <ThemeProvider
                theme={{
                  bg: Colors.Transparent,
                  fg: Colors.White,
                  ctaActiveFg: Colors.Robin,
                  ctaFg: Colors.White,
                  ctaIconFg: Colors.White,
                  ctaIconBg: Colors.White,
                  ctaHoverFg: Colors.Blue,
                  ctaHoverBg: Colors.White,
                  minPadding: '2em 0',
                }}
              >
                <CtaCardsGrid
                  ctaheaderBlock={ctaCardsHeader}
                  ctacardsBlock={ctaCards}
                />
              </ThemeProvider>
              <ThemeProvider theme={{headlineColor: Colors.Robin, ctaIconBg: Colors.Robin}}>
                <TwoUOSParts {...principles} />
              </ThemeProvider>
            </DarkContainer>
          </ThemeProvider>
          <LatestStyledSection />
          <CrosslinkSection noBg {...crossLink} />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
};

export default TwoUOS;

export const query = graphql`
  fragment TwoUOSFragment on CMS_TwouOSPage {
    seoTitle
    searchDescription
    canonicalUrl
    parentPage {
      title
      url
    }
    hero {
      ...headerBlockFragment
      ...videoCmsFragment
    }
    body {
      ...ctacardsHeaderFragment
      ...ctacardsFragment
      ...crosslinkBlockFragment
      ...iconCopyGridFragment
    }
  }
  query TwoUOS {
    cms {
      page(url: "/home/approach/2uos/") {
        ...TwoUOSFragment
      }
    }
    animatedVideo: file(relativePath: {eq: "2UOUS_CharcoalBkg.mp4"}) {
      publicURL
    }
  }
`;
