import React from 'react';
import {useSpring, animated, UseSpringProps} from 'react-spring';
import {SvgIconProps} from '.';
import {useTheme} from '@styles';

interface Props extends SvgIconProps {
  toggle: boolean;
}

const PlusButtonIcon = ({toggle, ariaHidden = true}: Props): JSX.Element => {
  const theme = useTheme();
  const {opacity} = useSpring<UseSpringProps<any>>({
    config: {duration: 175},
    to: {
      opacity: toggle ? 0 : 1,
    },
  });
  return (
    <svg width="43" height="43" aria-hidden={ariaHidden}>
      <g fillRule="nonzero" fill="none">
        <circle fill={theme.ctaIconBg} cx="21.5" cy="21.5" r="21.5" />
        <g fill="#FFF">
          <path d="M11.679 20.0632h18.6287c.8574 0 1.5524.695 1.5524 1.5524s-.695 1.5524-1.5524 1.5524H11.679c-.8573 0-1.5524-.695-1.5524-1.5524s.695-1.5524 1.5524-1.5524z" />
          <animated.path
            style={{opacity}}
            d="M22.385 12.2014v18.6288c0 .8573-.695 1.5524-1.5523 1.5524-.8574 0-1.5524-.695-1.5524-1.5524V12.2014c0-.8574.695-1.5524 1.5524-1.5524s1.5524.695 1.5524 1.5524z"
          />
        </g>
      </g>
    </svg>
  );
};

export default PlusButtonIcon;
