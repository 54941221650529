import React from 'react';
import useHover from '@hzdg/use-hover';
import {styled, theme, Fonts} from '@styles';
import {ResponsiveContainer} from '@components/layout';
import {ForwardLink} from '@components/Link';
import Image from '@components/Image';

export interface CardProps {
  imageBlock: {
    image: {
      url: string;
      height: number;
      width: number;
      focalPointX: number;
      focalPointY: number;
    };
    caption: string;
    attribution: string;
  };
  headerBlock: {
    header: string;
    subheader: string;
  };
  pagelinkBlock: PageLinkBlock;
  linkBlock: LinkBlock;
  alignment?: string;
}

interface PageLinkBlock {
  title: string;
  page: {
    url: string;
  };
}

interface LinkBlock {
  link: string;
  caption: string;
}

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'cardContainer'
})`
  background: ${theme.bg};
  max-width: 422px;
  position: relative;
  align-self: ${({alignment}: {alignment?: string}) =>
    alignment ? alignment : 'center'};
`;

const ContentWrapper = styled.div.withConfig({
  componentId: 'cardContentWrapper'
})`
  display: flex;
  justify-content: center;
`;

const ContentContainer = styled.div.withConfig({
  componentId: 'cardContentContainer'
})`
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5em 1.5em 1.8em;

  .wide & {
    padding: 3.125em 3.125em 2em;
  }
`;

const Title = styled.div.withConfig({
  componentId: 'cardTitle'
})`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${24 / 16}em;
  line-height: ${29 / 24};
  letter-spacing: ${-0.62 / 24}em;
  color: ${theme.ctaActiveFg};
  margin-bottom: 0.3em;

  .wide & {
    font-size: ${30 / 16}em;
    line-height: ${37 / 30};
    letter-spacing: ${-0.78 / 30}em;
  }
`;

const Copy = styled.div.withConfig({
  componentId: 'cardCopy'
})`
  ${Fonts.ProximaNova};
  font-size: ${16 / 16}em;
  line-height: ${24 / 16};
  letter-spacing: ${-0.41 / 16}em;
  color: ${theme.ctaFg};
  margin-bottom: 1em;
  max-width: 293px;

  .wide & {
    font-size: ${19 / 16}em;
    line-height: ${28 / 19};
    letter-spacing: ${-0.54 / 19}em;
  }
`;

const StyledForwardLink = styled(ForwardLink).withConfig({
  componentId: 'cardForwardLink'
})`
  ${Fonts.ProximaNova.variants.Bold};
  text-align: left;
  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;
// Note: we will either have a PageLinkBlock or a LinkBlock, according to product we shouldn't have both
function getCtaData(
  pagelinkBlock: PageLinkBlock,
  linkBlock: LinkBlock,
): {label: string | null; url: string | null} {

 
  if (linkBlock && linkBlock.link && linkBlock.caption) return {label: linkBlock.caption, url: linkBlock.link};
  else if (pagelinkBlock && pagelinkBlock.page && pagelinkBlock.title) {
    const {title, page} = pagelinkBlock;
    return {label: title, url: page.url};
  }
  return {label: null, url: null};
}

export default function Card({
  imageBlock,
  headerBlock,
  pagelinkBlock,
  linkBlock,
  alignment,
  className,
}: CardProps): JSX.Element {
  const {header, subheader} = headerBlock;
  const {caption, image} = imageBlock;
  const [isHover, hoverProps] = useHover();
  const {label, url} = getCtaData(pagelinkBlock, linkBlock);
  return (
    <Container alignment={alignment} className={className}>
      {image && (
        <Image childImageSharp={image.childImageSharp} alt={caption} />
      )}
      <ContentWrapper>
        <ContentContainer>
          {header && <Title>{header}</Title>}
          {subheader && <Copy>{subheader}</Copy>}
          {label && url && (
            <StyledForwardLink
              href={url}
              isHover={isHover}
              filledOnHover
              {...hoverProps}
            >
              {label}
            </StyledForwardLink>
          )}
        </ContentContainer>
      </ContentWrapper>
    </Container>
  );
}
