import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Image, {fluidImageFragment} from '@components/Image';
import HeroSectionContainer, {HeroSectionProps} from './HeroSectionContainer';
import {Paragraph} from '@components/typography';
import {styled, theme} from '@styles';
import { Script } from "gatsby"


export const HeroContentInner = styled.div.withConfig({
  componentId: 'heroContentInner'
})`
  width: 100%;
`;

export const HeroContentOuter = styled.div.withConfig({
  componentId: 'heroContentOuter'
})`
  background: ${theme.bg};
  /* calc height based on padding */
  height: calc(100% - 11em);
  padding: 8em 2em 3em;
  max-width: calc(100% - 4em);
  left: 0;
  margin: 0 auto;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  .narrow & {
    /* calc height based on padding */
    height: calc(100% - 6em);
    padding: 3em 2em;
  }
  .wide & {
    height: auto;
    max-width: ${theme.maxWidth};
    left: 10%;
  }
`;

export const Eyebrow = styled(Paragraph).withConfig({
  componentId: 'heroEyebrow'
})`
  text-transform: uppercase;
  font-weight: bold;
`;

const BackgroundImageContainer = styled.figure.withConfig({
  componentId: 'heroBackgroundImageContainer'
}).attrs(() => ({
  ['aria-hidden']: true,
}))`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  margin: 0;
`;

const HERO_IMAGE_QUERY = graphql`
  query HeroQuery($quality: Int) {
    homepageHero: file(relativePath: {eq: "HomeHero_2UBlueForward_Elm_Texture_Corner.png"}) {
      ...fluidImageFragment
    }
    secondaryImageHero: file(relativePath: {eq: "HomeHero_ElmForward_Little2UBlue.png"}) {
      ...fluidImageFragment
    }
    subpageHero: file(relativePath: {eq: "SubpageHero.jpg"}) {
      ...fluidImageFragment
    }
    twouOSHero: file(relativePath: {eq: "edx-hero.jpeg"}) {
      ...fluidImageFragment
    }
  }
`;

/** type will be expanded when the props have been supplied from gq query */
type HeroProps = HeroSectionProps;

export default function HeroSectionStandalone({
  heroSectionType = 'tall',
  altHero = false,
  twouOS = false,
  careerDetail = false,
  makeSectionShortTall = false,
  children,
  image,
  secondaryHero = false,
}: HeroProps): JSX.Element {
  const data = useStaticQuery(HERO_IMAGE_QUERY);
  
  const {childImageSharp: homepageHeroSharp} = data.homepageHero;
  const {childImageSharp: secondaryImageHero} = data.secondaryImageHero;
  const {childImageSharp: subpageHeroSharp} = data.subpageHero;
  const {childImageSharp: twouOSHeroSharp} = data.twouOSHero;
  
  if (altHero) image = subpageHeroSharp;
  else if (twouOS) image = twouOSHeroSharp;
  else if (image) image = image;
  else if (secondaryHero) image = secondaryImageHero;
  else image = homepageHeroSharp;
  return (
    <HeroSectionContainer
      heroSectionType={heroSectionType}
      careerDetail={careerDetail}
      makeSectionShortTall={makeSectionShortTall}
    >
      <BackgroundImageContainer>
        
        <Image
          childImageSharp={image}
          imgStyle={{
            objectPosition: 'center right',
          }}
          style={{height: '100%'}}
          preservePresentationWidth={false}
          loading="eager"
        />
      </BackgroundImageContainer>
      <HeroContentOuter>
        <HeroContentInner>{children}</HeroContentInner>
      </HeroContentOuter>
    </HeroSectionContainer>
  );
}
